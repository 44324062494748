import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import './Admin.css';
import { CREATE_ACCOUNT_URI, SIGN_IN_URI } from 'src/contants';

import { NetworkProvider } from '../../NetworkProvider';
import CategoriesReducer from 'src/Utilities/CategoriesReducer';
import { AuthContext } from 'src/AuthProvider';
import FullScreenLoader from 'src/Elements/FullScreenLoader';
import { LogoIcon } from 'src/Elements/Icons';

import Categories from './Categories/Categories';
import Recipes from './Recipes/Recipes';
import AuthorMealPlans from './Recipes/AuthorMealPlans/AuthorMealPlans';
import VipTraining from './VipTraining/VipTraining';
import PaymentMethods from './PaymentMethods/PaymentMethods';
import MyInvoices from './MyInvoices/MyInvoices';
import MyCompanyInfo from './MyCompanyInfo/MyCompanyInfo';
import DailyVideo from './DailyVideo/DailyVideo';
import Greeting from './Greeting/Greeting';
import Notifications from './Notifications/Notifications';
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";

import NavigationContext from '../Navigation/NavigationContext';
import { CategoriesContext } from './Categories/CategoriesContext';

import Popup from '../Popup/Popup';
import ConfirmUnsubscribePopupContent from 'src/Utilities/Popups/ConfirmUnsubscribe/ConfirmUnsubscribePopupContent';

function Admin() {

  const navigate = useNavigate();
  const [selected, setSelected] = useState(1);
  const [collapsed, setCollapsed] = useState(window.innerWidth < 768);
  const [showContent, setShowContent] = useState(false);
  const { loginCredentials, setLoginCredentials, clearCredentials } = useContext(AuthContext);
  const [loadingText, setLoadingText] = useState(null);
  const location = useLocation();

  const [authorData, setAuthorData] = useState(location.state ? location.state.authorData : null);

  // Unsubscribe alert
  const [unsubscribeRequestSubId, setUnsubscribeRequestSubId] = useState(null);
  const [isUnsubscribeOpen, setIsUnsubscribeOpen] = useState(true);

  const [recipes, setRecipes] = useState([]);
  const [mealPlans, setMealPlans] = useState([]);

  const { setOnboardingState } = useContext(NavigationContext);

  const { fetchCategories } = useContext(CategoriesContext)

  const handleWindowResize = () => {
    setCollapsed(window.innerWidth < 768);
  };

  useEffect(() => {
    fetchCategories();
    fetchRecipes();
    fetchAuthorIfNeeded();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleItemClick = (item) => {
    setSelected(item);
    if (collapsed) {
      setShowContent(true);
    }
  };

  const handleBackClick = () => {
    setShowContent(false);
  };

  const handleCloseUnsubscribe = () => {
    setUnsubscribeRequestSubId(null);
  };

  const onUnsubscribeRequested = (subscriptionId) => {
    setUnsubscribeRequestSubId(subscriptionId)
  };

  const confirmUnsubscribeCallback = (email, subscriptionId) => {
    /*
    if (!subscriptionId) {
      return
    }

    const validatedEmail = validated_email(email);

    NetworkProvider.cancelSubscription(clientData.token, validatedEmail, subscriptionId)
      .then(data => {
        setUnsubscribeRequestSubId(null);
      })
      .catch(error => {
        window.alert(error);
      });*/
  }

  const fetchRecipes = async () => {
    try {
      const rawRecipes = await NetworkProvider.get_recipes(loginCredentials, setLoginCredentials)
      const rawMealPlans = await NetworkProvider.get_food_categories(loginCredentials, setLoginCredentials)
      const mealPlans = CategoriesReducer.assignRecipesToPlans(rawMealPlans, rawRecipes)

      setMealPlans(mealPlans)
    } catch (error) {
      window.alert(error);
    }
  };

  const fetchAuthorIfNeeded = async () => {
    if (!authorData) {
      const newAuthorData = await NetworkProvider.get_author(loginCredentials, setLoginCredentials);
      setAuthorData(newAuthorData);
    }
  }

  const LogoutButton = () => {
    const handleLogout = async () => {
      clearCredentials();
      setOnboardingState(0)
      navigate(SIGN_IN_URI);
    };

    return (
      <button onClick={handleLogout}>
        Log out
      </button>
    );
  };

  return (
    <div className="admin-content">
      <Helmet>
        <script type="application/ld+json">
          {`
                  {
                      "@context": "https://schema.org",
                      "@type": "WebPage",
                      "name": "Admin Panel",
                      "description": "appscribe.io Admin panel",
                      "url": "${window.location.href}",
                      "about": {
                          "@type": "Organization",
                          "name": "Appscribe.io",
                          "description": "Your Easy Fitness App Solution",
                          "address": {
                              "@type": "PostalAddress",
                              "streetAddress": "Beberbeķu iela 46",
                              "addressLocality": "Rīga",
                              "addressRegion": "Vidzeme",
                              "postalCode": "LV-1006",
                              "addressCountry": "Latvia"
                          },
                          "vatID": "LV40203159237"
                      }
                  }
              `}
        </script>
      </Helmet>
      <div className={`side-menu ${collapsed && showContent ? 'hidden' : ''}`}>
        <div className="menuItemContainer">
          <div className='logoContainer'>
            <LogoIcon />
          </div>

          <div className='menu-header'>App Content</div>
          <button className={selected === 1 ? 'selected' : ''} onClick={() => handleItemClick(1)}>
            Workouts
          </button>

          <button className={selected === 2 ? 'selected' : ''} onClick={() => handleItemClick(2)}>
            Meal plans
          </button>

          <button className={selected === 3 ? 'selected' : ''} onClick={() => handleItemClick(3)}>
            Greeting
          </button>

          <button className={selected === 4 ? 'selected' : ''} onClick={() => handleItemClick(4)}>
            Daily video
          </button>

          <button className={selected === 5 ? 'selected' : ''} onClick={() => handleItemClick(5)}>
            Notifications
          </button>

          <div className='menu-header'>VIP Coaching</div>

          <button className={selected === 6 ? 'selected' : ''} onClick={() => handleItemClick(6)}>
            Users
          </button>

          <div className='menu-header'>My Account</div>

          <button className={selected === 7 ? 'selected' : ''} onClick={() => handleItemClick(7)}>
            Payment methods
          </button>

          <button className={selected === 8 ? 'selected' : ''} onClick={() => handleItemClick(8)}>
            Invoices
          </button>

          <button className={selected === 9 ? 'selected' : ''} onClick={() => handleItemClick(9)}>
            Billing info
          </button>
        </div>
        <div className="menuItemContainer">
          <LogoutButton />
        </div>
      </div>
      <div className={`content ${collapsed && !showContent ? 'hidden' : ''}`}>
        {collapsed && !showContent && <div onClick={() => handleItemClick(selected)} className={"overlay"}></div>}
        {collapsed && (
          <div className={"header"}>
            <div className={"backBtn"}>
              <IconButton onClick={handleBackClick} aria-label="menu">
                <MenuIcon fontSize="medium" />
              </IconButton>
            </div>
          </div>
        )}
        {selected === 1 && <Categories setLoadingText={setLoadingText} />}
        {selected === 2 && <AuthorMealPlans
          mealPlans={mealPlans}
          setMealPlans={setMealPlans}
          setLoadingText={setLoadingText}
        />}
        {selected === 3 && <Greeting setLoadingText={setLoadingText} />}
        {selected === 4 && <DailyVideo setLoadingText={setLoadingText} />}
        {selected === 5 && <Notifications setLoadingText={setLoadingText} />}
        {selected === 6 && <VipTraining setLoadingText={setLoadingText} />}
        {selected === 7 && <PaymentMethods setLoadingText={setLoadingText} />}
        {selected === 8 && <MyInvoices />}
        {selected === 9 && <MyCompanyInfo setLoadingText={setLoadingText} authorData={authorData} />}
      </div>
      {unsubscribeRequestSubId && (
        <Popup onClose={handleCloseUnsubscribe} isOpenProp={isUnsubscribeOpen}>
          <ConfirmUnsubscribePopupContent
            confirmUnsubscribeCallback={confirmUnsubscribeCallback}
            subscriptionId={unsubscribeRequestSubId} />
        </Popup>
      )
      }
      <FullScreenLoader loadingText={loadingText} />
    </div>
  );
}

export default Admin;