import React, { useState, useEffect, useRef, useContext } from 'react';
import { AuthContext } from 'src/AuthProvider';

import { NetworkProvider } from 'src/NetworkProvider';
import CategoriesReducer from 'src/Utilities/CategoriesReducer';
import TrainingCalendar from './TrainingCalendar';
import DuplicateDeletePopover from './DuplicateDeletePopover';
import { CategoriesContext } from '../CategoriesContext';

const PlanCalendar = ({ plan, onPlanUpdated, onClose, setLoadingText }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorWeekIndex, setAnchorWeekIndex] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const initialPlan = {
        weeks: [{
            index: 0,
            days: []
        }],
    };

    const initialWeek = {
        index: 0,
        days: [
            { index: 0, id: 0, name: '', image: '', exercises: [] },
            { index: 1, id: 1, name: '', image: '', exercises: [] },
            { index: 2, id: 2, name: '', image: '', exercises: [] },
            { index: 3, id: 4, name: '', image: '', exercises: [] },
            { index: 4, id: 5, name: '', image: '', exercises: [] },
            { index: 5, id: 6, name: '', image: '', exercises: [] },
            { index: 6, id: 7, name: '', image: '', exercises: [] }
        ]
    };

    const [localPlan, setLocalPlan] = useState(initialPlan);
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
    const { loginCredentials, setLoginCredentials } = useContext(AuthContext);
    const { deleteWeek } = useContext(CategoriesContext);

    const [editingExerciseDay, setEditingExerciseDay] = useState({});
    const [openDay, setOpenDay] = useState([]);
    const isInitialMount = useRef(true);


    useEffect(() => {
        setLocalPlan(plan);
    }, [plan]);

    useEffect(() => {
        if (!isInitialMount.current) {
            onPlanUpdated(localPlan);
        } else {
            isInitialMount.current = false;
        }
    }, [localPlan]);


    const onEmptyDayClick = (day, week) => {
        if (!day && !week) {
            setEditingExerciseDay({ day: -1, week: -1 })
            return
        }
        setEditingExerciseDay({ day: day.index, week: week.index })
    }

    const onShouldAddExerciseClick = (day, week) => {
        const selectedWeek = localPlan.weeks.find(w => w.index === week.index);
        const selectedDay = selectedWeek.days.find(selectedWeekDay => selectedWeekDay.index === day.index);

        setEditingExerciseDay({ day: day.index, week: week.index })
        setOpenDay(selectedDay);
        setIsSideMenuOpen(true);
    }

    const addWeek = async () => {
        const newWeekIndex = CategoriesReducer.getMaxWeekIndex(localPlan.weeks);
        const newWeek = { days: initialWeek.days, index: newWeekIndex + 1 };

        try {
            const newWeekInResponse = await NetworkProvider.add_week(loginCredentials, setLoginCredentials, newWeek, plan.id);
            newWeekInResponse.days.sort((a, b) => a.index - b.index);
            setLocalPlan({ ...localPlan, weeks: [...localPlan.weeks, newWeekInResponse] });
        } catch (error) {
            window.alert(error);
        }
    };

    const onDayUpdated = (newDay) => {
        const updatedPlan = { ...localPlan };
        const { week: weekIndex, day: dayIndex } = editingExerciseDay;

        if (weekIndex >= 0 && weekIndex < updatedPlan.weeks.length &&
            dayIndex >= 0 && dayIndex < updatedPlan.weeks[weekIndex].days.length) {
            updatedPlan.weeks[weekIndex].days[dayIndex] = newDay;

            setLocalPlan(updatedPlan);
        }
    };

    const onDoneClick = async () => {
        setIsSideMenuOpen(false);
        setEditingExerciseDay({});

        try {
            const updatedWeeks = await NetworkProvider.update_weeks(loginCredentials, setLoginCredentials, localPlan);

            const updatedPlan = {
                ...localPlan,
                weeks: updatedWeeks
            };

            setLocalPlan(updatedPlan);
        } catch (error) {
            window.alert(error);
        }
    };

    const onDeleteWeekClick = async () => {

        if (anchorWeekIndex == null) {
            window.alert("No week selected")
            return;
        }

        const weekIndex = anchorWeekIndex;
        const isConfirmed = window.confirm("Are you sure you want to delete week " + (weekIndex + 1) + " with all exercises?");

        if (!isConfirmed) {
            return;
        }

        const week = localPlan.weeks[weekIndex]

        try {
            setLoadingText("Deleting week")
            await deleteWeek(week);

            const weekId = week.id;
            await NetworkProvider.delete_week(loginCredentials, setLoginCredentials, weekId, localPlan.id);

            setLocalPlan(currentPlan => ({
                ...currentPlan,
                weeks: currentPlan.weeks.filter((_, index) => index !== weekIndex)
            }));

            setLoadingText(null)
        } catch (error) {
            window.alert(error);
        }
    }

    const onDuplicateWeekClick = async () => {
        if (anchorWeekIndex == null) {
            window.alert("No week selected")
            return;
        }

        const weekIndex = anchorWeekIndex;
        const isConfirmed = window.confirm("Are you sure you want to duplicate week " + (weekIndex + 1));

        if (!isConfirmed) {
            return;
        }

        const weekToCopy = localPlan.weeks[weekIndex];

        if (!weekToCopy) {
            window.alert("Week does not exist.");
            return;
        }

        const newWeekIndex = CategoriesReducer.getMaxWeekIndex(localPlan.weeks)
        const newWeek = { days: weekToCopy.days, index: newWeekIndex + 1 };
        const newWeekInResponse = await NetworkProvider.add_week(loginCredentials, setLoginCredentials, newWeek, localPlan.id);
        newWeekInResponse.days.sort((a, b) => a.index - b.index);
        try {
            setLoadingText("Duplicating week");
            setLocalPlan(currentPlan => ({
                ...currentPlan,
                weeks: [...currentPlan.weeks, newWeekInResponse]
            }));

            setLoadingText(null);
        } catch (error) {
            window.alert("Failed to duplicate week: " + error.message);
        }
    }

    const onThreeDotsClick = (event, weekIndex) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setAnchorWeekIndex(weekIndex);
    }

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorWeekIndex(null)
    };

    return (
        <>
            <TrainingCalendar
                localPlan={localPlan}
                editingExerciseDay={editingExerciseDay}
                loginCredentials={loginCredentials}
                isSideMenuOpen={isSideMenuOpen}
                openDay={openDay}
                onThreeDotsClick={onThreeDotsClick}
                onShouldAddExerciseClick={onShouldAddExerciseClick}
                onEmptyDayClick={onEmptyDayClick}
                addWeek={addWeek}
                onDoneClick={onDoneClick}
                setLoadingText={setLoadingText}
                onDayUpdated={onDayUpdated}
                handleClose={handleClose}
                onDuplicateWeekClick={onDuplicateWeekClick}
                onDeleteWeekClick={onDeleteWeekClick}
                setIsSideMenuOpen={setIsSideMenuOpen}
                useCategoriesNetwork={true}
            />
            {anchorEl &&
                <DuplicateDeletePopover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    onDuplicateClick={() => onDuplicateWeekClick()}
                    onDeleteClick={() => onDeleteWeekClick()}
                />
            }
        </>
    );
};

export default PlanCalendar;
