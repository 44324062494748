import { Card, LinearProgress, Typography, Box, Avatar, CircularProgress, Grid, TextField } from "@mui/material";
import WaterIcon from 'src/Assets/cup-straw.svg';
import EmptyWaterIcon from 'src/Assets/cup-straw-empty.svg';
import { useState, useEffect, useContext } from "react";
import VipCodeReducer from "./VipCodeReducer";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { CategoriesContext } from "../../Categories/CategoriesContext";
import { image_url_for_image } from "src/Utilities/Utilities";


const UserVipCodeOverView = ({
    userData,
    firstWeight,
    requiredProtein,
    requiredFat,
    requiredCarbs,
    goal,
    lastDate,
    caloriesIntake,
    height
}) => {

    const [selectedDate, setSelectedDate] = useState(null);
    const [activeData, setActiveData] = useState(null);
    const { getPlanDetailsByPlanId } = useContext(CategoriesContext);

    const allowedDates = new Set(Object.keys(userData));

    useEffect(() => {
        if (selectedDate) {
            const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");
            setActiveData(userData[formattedDate])
        }
    }, [selectedDate]);

    useEffect(() => {
        if (lastDate) {
            const materialDate = dayjs(lastDate);
            setSelectedDate(materialDate);
        }
    }, [lastDate]);


    const isDateAllowed = (date) => {
        const formattedDate = dayjs(date).format("YYYY-MM-DD");
        const isAllowed = allowedDates.has(formattedDate);
        return isAllowed;
    };


    const weightCard = (weight, height, firstWeight) => {
        const bmi = (firstWeight && height) ? Math.round(firstWeight.value / ((height / 100) ** 2)) : undefined;
        return (
            <Card sx={{ borderRadius: 3, p: 2, mb: 2 }}>
                <Grid container spacing={2} justifyContent="space-between">
                    {/* Left Grid (Current Weight) */}
                    <Grid item xs={6}>
                        <Typography variant="h6" sx={{ fontSize: "12px" }}>
                            Current
                        </Typography>
                        <Typography variant="h4" fontWeight="bold">
                            {weight.value} <span style={{ fontSize: "16px", fontWeight: "normal" }}>{weight.unit}</span>
                        </Typography>
                    </Grid>

                    {/* Right Grid (Aligned to Right) */}
                    <Grid
                        item
                        xs={4}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "right", // Ensures numbers align correctly
                            ml: "auto" // Pushes grid to the right
                        }}
                    >
                        {firstWeight &&
                            <Typography sx={{ fontSize: "12px", display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <strong style={{ color: "#8089A6" }}>Starting</strong>
                                <span>{firstWeight.value}{firstWeight.unit}</span>
                            </Typography>
                        }
                        {bmi &&
                            <Typography sx={{ fontSize: "12px", display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <strong style={{ color: "#8089A6" }}>BMI</strong>
                                <span>{bmi}</span>
                            </Typography>
                        }
                    </Grid>
                </Grid>
            </Card>
        )
    }

    const nutritionCard = (carbs, fat, protein, eatenCalories, burnedCalories, requiredCarbs, requiredFat, requiredProtein, caloriesIntake) => {
        const caloriesLeft = Math.max(0, Math.round(caloriesIntake + burnedCalories - eatenCalories))
        return (
            <Card sx={{ borderRadius: 3, p: 3, textAlign: "center" }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                        <Typography sx={{ fontSize: "19px", fontWeight: 400 }}>{eatenCalories}</Typography>
                        <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>Eaten</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{ position: "relative", display: "inline-flex" }}>
                            {/* Circular Progress */}
                            <CircularProgress
                                variant="determinate"
                                value={60}
                                size={110}
                                thickness={3}
                                sx={{ color: "#4182E3" }}
                            />

                            {/* Centered Text */}
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)", // Centers text
                                    textAlign: "center",
                                }}
                            >
                                <Typography fontWeight="bold" sx={{ fontSize: "18px" }}>{caloriesLeft}</Typography>
                                <Typography sx={{ fontSize: "9px", fontWeight: 700 }}>KCAL LEFT</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography sx={{ fontSize: "19px", fontWeight: 400 }}>{burnedCalories}</Typography>
                        <Typography
                            variant="body2"
                            color="black"
                            sx={{ fontSize: "12px", fontWeight: 700 }}
                        >Burned</Typography>
                    </Grid>
                </Grid>
                {macroProgress(carbs, fat, protein, requiredCarbs, requiredFat, requiredProtein)}
            </Card>
        )
    }

    const macroProgress = (carbs, fat, protein, requiredCarbs, requiredFat, requiredProtein) => {
        const macros = [
            { label: "Carbs", value: carbs, max: requiredCarbs },
            { label: "Fat", value: fat, max: requiredFat },
            { label: "Protein", value: protein, max: requiredProtein }
        ];

        return (
            <Box display="flex" justifyContent="space-between" mt={2}>
                {macros.map((macro, index) => {
                    const progress = Math.min((macro.value / macro.max) * 100, 100);
                    return (
                        <Box key={index} textAlign="center" width="25%">
                            <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
                                {macro.label}
                            </Typography>

                            <Box sx={{ width: "60%", mx: "auto" }}>
                                <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                    sx={{
                                        height: 3,
                                        borderRadius: 3,
                                        backgroundColor: "#E0E0E0",
                                        "& .MuiLinearProgress-bar": {
                                            backgroundColor: "#4182E3"
                                        }
                                    }}
                                />
                            </Box>

                            <Typography sx={{ fontSize: "14px", fontWeight: 400, color: "#808080", mt: 0.5 }}>
                                {macro.value} / {macro.max}g
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
        );
    };

    const exercises = (exercises) => {
        return (
            <Grid item>
                <Typography sx={{ color: "##414754", fontWeight: 700, fontSize: "16px" }}>
                    Exercises
                </Typography>
                <Card sx={{ borderRadius: 3 }}>
                    {exercises.map((exercise, index) => {

                        let planDetails = null;
                        let imageUrl = null;
                        if (exercise.plan_id) {
                            planDetails = getPlanDetailsByPlanId(exercise.plan_id)
                            if (planDetails) {
                                imageUrl = image_url_for_image(planDetails.image)
                            }
                        }
                        return (
                            <Box key={index} display="flex" alignItems="center" p={2}>
                                <Avatar
                                    src={imageUrl}
                                    sx={{ width: 48, height: 48, mr: 2 }}
                                />
                                <Box flexGrow={1}>
                                    <Typography sx={{ color: "#313131", fontWeight: 600, fontSize: "16px" }}>{planDetails ? planDetails.name : exercise.exercises}</Typography>
                                </Box>
                                <Typography sx={{ color: "#313131", fontWeight: 600, fontSize: "16px" }}>{exercise.calories} Cal</Typography>
                            </Box>
                        )
                    })}
                </Card>
            </Grid>
        )
    }

    const waterIntake = (water) => {
        const waterLevels = [0.3, 0.6, 1.0, 1.3, 1.6, 2];

        let index = waterLevels.findIndex(level => level === Number(Number(water.level).toFixed(1)));

        if (index == -1) {
            return null
        }

        const intakeLevels = ["Bad", "Poor", "Average", "Good", "Very good", "Excellent"]

        let intakeColor = undefined;
        switch (index) {
            case 0:
            case 1:
                intakeColor = "#F1372B"
                break
            case 2:
            case 3:
                intakeColor = "#F8CE65"
                break
            case 4:
            case 5:
                intakeColor = "#27AF4D";
                break
            default:
                intakeColor = "#F1372B"
                break
        }

        return (
            <Grid item>
                <Typography variant="h6" fontWeight="bold">
                    Water <span style={{ color: intakeColor }}>{intakeLevels[index]}</span>
                </Typography>
                <Box display="flex" alignItems="center" gap={5}>
                    {waterLevels.map((amount, index) => {
                        const isFilled = amount <= parseFloat(water.level);
                        return (
                            <Box key={index} display="flex" flexDirection="column" alignItems="center">
                                <img
                                    src={isFilled ? WaterIcon : EmptyWaterIcon}
                                    alt="Water bottle"
                                    style={{ width: 30 }}
                                />
                                <Typography
                                    variant="caption"
                                    sx={{ mt: 0.5, visibility: isFilled ? 'visible' : 'hidden' }}
                                >
                                    {amount}
                                </Typography>
                            </Box>
                        );
                    })}
                </Box>
            </Grid>
        );
    };

    return (
        <Box sx={{ p: 2, bgcolor: "#f8fafc" }}>
            <Grid container spacing={3}>
                {/* Left Side: Weight and Nutrition */}
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" fontWeight="bold" sx={{ fontSize: "16px", mb: 1 }}>
                        Weight and Nutrition
                    </Typography>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Select a Date"
                                value={selectedDate}
                                shouldDisableDate={(date) => {
                                    const isAllowed = isDateAllowed(date);
                                    return !isAllowed;
                                }}
                                onChange={(newValue) => setSelectedDate(newValue)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    {activeData?.weight && weightCard(activeData.weight, height, firstWeight)}
                    {activeData && nutritionCard(
                        activeData.carbs,
                        activeData.fat,
                        activeData.protein,
                        activeData.eatenCalories,
                        activeData.burnedCalories,
                        requiredCarbs,
                        requiredFat,
                        requiredProtein,
                        caloriesIntake
                    )}
                </Grid>

                {/* Right Side: Exercises & Water Intake */}
                <Grid item xs={12} md={6} container spacing={3} direction="column">
                    {/* Exercises (Top Right) */}
                    {activeData?.exercises && exercises(activeData.exercises)}
                    {activeData?.water &&
                        waterIntake(activeData.water)
                    }
                </Grid>
            </Grid>
        </Box>
    );
};

export default UserVipCodeOverView;
