import React, { useState, useEffect, useContext } from "react";
import MealPlans from "../FoodCategories/MealPlans";
import VipCodeReducer from "../../VipTraining/UserVipCode/VipCodeReducer";
import { CategoriesContext } from "../../Categories/CategoriesContext";
import { generateUniqueId } from 'src/Utilities/Utilities';
import { NetworkProvider } from "src/NetworkProvider";
import { AuthContext } from 'src/AuthProvider';

const AuthorMealPlans = ({ mealPlans, setMealPlans, setLoadingText }) => {
    const [editMealPlan, setEditMealPlan] = useState(null);
    const [deleteMealPlan, setDeleteMealPlan] = useState(null);
    const [openRecipe, setOpenRecipe] = useState(null);
    const [openMealPlan, setOpenMealPlan] = useState(null);
    const { loginCredentials, setLoginCredentials } = useContext(AuthContext);

    useEffect(() => {
        if (openMealPlan) {
            const updatedMealPlan = mealPlans.find(plan => plan.id === openMealPlan.id);
            if (updatedMealPlan) {
                setOpenMealPlan(updatedMealPlan);
            } else {
                setOpenMealPlan(null);
            }
        }
        if (openRecipe) {
            const updatedOpenRecipe = mealPlans.find(recipe => recipe.id === openRecipe.id);
            if (updatedOpenRecipe) {
                setOpenRecipe(updatedOpenRecipe);
            } else {
                setOpenRecipe(null);
            }
        }
    }, [mealPlans]);


    const onCreateMealPlan = async (planName) => {
        try {
            if (openMealPlan) {
                setLoadingText('Updating meal plan...');
                await NetworkProvider.update_food_category(loginCredentials, setLoginCredentials, planName, openMealPlan.id, openMealPlan.index);
                setMealPlans((prevPlans) =>
                    prevPlans.map((mealPlan) => (mealPlan.id === openMealPlan.id ? { ...mealPlan, title: planName } : mealPlan))
                );
                setOpenMealPlan(null);
                setLoadingText(null);
            } else {
                setLoadingText('Adding meal plan...');
                const index = mealPlans.length + 1;
                const response = await NetworkProvider.add_food_category(loginCredentials, setLoginCredentials, planName, index);
                setMealPlans((prevPlans) => {
                    const updatedMealPlans = [...prevPlans, response];
                    return updatedMealPlans.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                });
                setOpenMealPlan(null);
                setLoadingText(null);
            }
        } catch (error) {
            setLoadingText(null);
            alert(error);
        }
    }

    const onReorderMealPlans = async (newPlans) => {
        try {
            await Promise.all(newPlans.map(mealPlan =>
                NetworkProvider.update_food_category(loginCredentials, setLoginCredentials,
                    mealPlan.title,
                    mealPlan.id,
                    mealPlan.index
                )
            ));
            setMealPlans(newPlans)
        } catch (error) {
            window.alert(error);
        }
    }

    const onDeleteMealPlan = async (planToDelete) => {
        try {
            setLoadingText('Deleting meal plan...');
            await NetworkProvider.delete_food_category(loginCredentials, setLoginCredentials, planToDelete.id);
            setMealPlans((prevPlans) => prevPlans.filter((mealPlan) => mealPlan.id !== planToDelete.id));
            setOpenMealPlan(null);
            setDeleteMealPlan(null);
            setLoadingText(null);
        } catch (error) {
            setOpenMealPlan(null);
            setDeleteMealPlan(null);
            setLoadingText(null);
            window.alert(error);
        }
    };

    const onAddRecipe = async (recipeToAdd) => {
        try {
            if (!openMealPlan) {
                throw new Error("No open meal plan")
            }

            const recipeData = await NetworkProvider.add_recipe(loginCredentials, setLoginCredentials, recipeToAdd);
            const updatedMealPlan = {
                ...openMealPlan,
                recipes: [...(openMealPlan.recipes || []), recipeData]
            };

            setMealPlans((prevMealPlans) =>
                prevMealPlans.map(plan => plan.id === updatedMealPlan.id ? updatedMealPlan : plan)
            );
        } catch (error) {
            window.alert(error)
        }
    };

    const onEditedRecipe = async (updatedRecipe) => {
        try {
            if (!openMealPlan) {
                throw new Error("No open meal plan")
            }

            const recipeData = await NetworkProvider.update_recipe(loginCredentials, setLoginCredentials, updatedRecipe);
            const updatedMealPlan = {
                ...openMealPlan,
                recipes: openMealPlan.recipes
                    ? openMealPlan.recipes.map(recipe =>
                        recipe.id === recipeData.id ? recipeData : recipe // Replace if ID matches
                    )
                    : [recipeData] // If no recipes exist, create a new array with the recipe
            };

            setMealPlans((prevMealPlans) =>
                prevMealPlans.map(plan => plan.id === updatedMealPlan.id ? updatedMealPlan : plan)
            );
        } catch (error) {
            window.alert(error)
        }
    };

    const onDeleteRecipe = async (recipeId) => {
        try {
            if (!openMealPlan) {
                throw new Error("No open meal plan")
            }
            const recipeToDelete = openMealPlan.recipes.find(recipe => recipe.id === recipeId);
            if (!recipeToDelete) {
                throw new Error("No Recipe to delete found")
            }

            await NetworkProvider.delete_recipe(loginCredentials, setLoginCredentials, recipeToDelete);

            if (recipeToDelete.image != "") {
                setLoadingText("Deleting recipe image...")

                try {
                    await NetworkProvider.delete_image(loginCredentials, setLoginCredentials, recipeToDelete.image)
                } catch { }
            }
            if (!openMealPlan) {
                return;
            }

            const updatedMealPlan = {
                ...openMealPlan,
                recipes: openMealPlan.recipes.filter(recipe => recipe.id !== recipeId)
            };
            setMealPlans((prevMealPlans) =>
                prevMealPlans.map(plan => plan.id === updatedMealPlan.id ? updatedMealPlan : plan)
            );
            setOpenRecipe(null)
            setLoadingText(null)
        } catch (error) {
            setOpenRecipe(null)
            setLoadingText(null)
            alert(error)
        }
    };

    return (
        <div>
            <MealPlans
                mealPlans={mealPlans}
                setMealPlans={setMealPlans}
                editMealPlan={editMealPlan}
                setEditMealPlan={setEditMealPlan}
                setLoadingText={setLoadingText}
                openRecipe={openRecipe}
                setOpenRecipe={setOpenRecipe}
                onCreateMealPlan={onCreateMealPlan}
                onReorderMealPlans={onReorderMealPlans}
                onDeleteMealPlan={onDeleteMealPlan}
                deleteMealPlan={deleteMealPlan}
                setDeleteMealPlan={setDeleteMealPlan}
                onAddRecipe={onAddRecipe}
                openMealPlan={openMealPlan}
                setOpenMealPlan={setOpenMealPlan}
                onEditedRecipe={onEditedRecipe}
                onDeleteRecipe={onDeleteRecipe}
            />
        </div>
    );
};

export default AuthorMealPlans;
