import React, { useState } from "react";
import VipPlans from "../VipPlans";
import VIPCalendar from "../VipCalendar";

import { Dialog, DialogTitle, DialogContent, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import VipUserHeader from "../VipUserHeader";

const StateEnum = {
    VIP_PLANS: 1,
    VIP_CALENDAR: 2
};

const UserVipCodePlans = ({
    selectedUserVipCode,
    setLoadingText
}) => {
    const [state, setState] = useState(StateEnum.VIP_PLANS);
    const [selectedPlan, setSelectedPlan] = useState(null);

    const onPlanClick = (plan) => {
        setSelectedPlan(plan)
        setState(StateEnum.VIP_CALENDAR);
    }

    const onCloseVipCalendar = (plan) => {
        setSelectedPlan(null)
        setState(StateEnum.VIP_PLANS);
    }

    const handleCloseDialog = () => {
        setState(StateEnum.VIP_PLANS);
    }

    return (
        <>
            {state === StateEnum.VIP_PLANS && selectedUserVipCode && (
                <VipPlans
                    userVipCode={selectedUserVipCode}
                    onPlanClick={onPlanClick}
                    setLoadingText={setLoadingText}
                />
            )}
            <Dialog
                open={state === StateEnum.VIP_CALENDAR && selectedPlan}
                onClose={handleCloseDialog}
                maxWidth="md"
                fullWidth
                sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }} // ✅ Dialog structure should remain column
                disableTypography
            >
                <DialogTitle
                    disableTypography
                    sx={{ display: "flex", alignItems: "center", gap: 1 }} // Ensures elements align in a row and are centered
                >
                    <IconButton aria-label="close" onClick={handleCloseDialog}>
                        <CloseIcon />
                    </IconButton>
                    <span
                        style={{
                            fontSize: "22px",
                            fontWeight: "bold",
                            paddingLeft: '10px'
                        }}
                    >
                        {selectedUserVipCode.name} {selectedPlan ? ` > ${selectedPlan.name}` : ""}
                    </span>
                </DialogTitle>



                <DialogContent sx={{ flexGrow: 1, overflow: 'auto' }}>
                    <VIPCalendar
                        userVipCode={selectedUserVipCode}
                        plan={selectedPlan}
                        setLoadingText={setLoadingText}
                        onClose={onCloseVipCalendar}
                    />
                </DialogContent>
            </Dialog>

        </>
    );
};

export default UserVipCodePlans;