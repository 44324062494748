import React, { useState } from 'react';
import BlueButton from 'src/Elements/BlueButton';
import { makeStyles } from '@material-ui/core/styles';
import AddFoodCategoryDialog from './AddFoodCategoryDialog';
import Recipes from '../Recipes';
import FoodCategoriesCollection from './FoodCategoriesCollection';
import DeleteConfirmDialog from '../../../../Utilities/Popups/DeleteConfirmDialog';

import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import { DialogTitle, IconButton } from '@material-ui/core';

const StateEnum = {
    MAIN: 0,
    FILTEREDRECIPES: 1,
};

const MealPlans = ({
    mealPlans,
    setMealPlans,
    setLoadingText,
    openRecipe,
    setOpenRecipe,
    onDeleteMealPlan,
    onReorderMealPlans,
    onCreateMealPlan,
    onEditedMealPlan,
    onClose,
    deleteMealPlan,
    setDeleteMealPlan,
    onAddRecipe,
    openMealPlan,
    setOpenMealPlan,
    onEditedRecipe,
    onDeleteRecipe
}) => {
    const [state, setState] = useState(StateEnum.MAIN);
    const [editingCategory, setEditingCategory] = useState(null);
    const [isCreateEditCategoryOpen, setIsCreateEditCategoryOpen] = useState(null);
    const [activeId, setActiveId] = useState(null);
    const classes = useStyles();

    const title = openMealPlan ? (openMealPlan.title) : ""

    const handleCategoryClick = (category) => {
        setOpenMealPlan(category);
        setState(StateEnum.FILTEREDRECIPES);
    };


    const handleCategoryEditClick = (category) => {
        setEditingCategory(category);
        setIsCreateEditCategoryOpen(true);
    };

    const handleClose = () => {
        onClose();
    };

    const CategoriesListContent = () => {
        if (mealPlans == null || mealPlans.length === 0) {
            return (
                <div className={classes.addCategoryWrapper}>
                    <div className={classes.addCategoryContainer}>
                        <h3 className={classes.exampleTitle}>
                            Lets add your first meal plan
                        </h3>
                        <BlueButton onClick={() => setIsCreateEditCategoryOpen(true)}>Add meal plan</BlueButton>
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    <header className={classes.header}>
                        <div className={classes.headerButton}>
                            <BlueButton onClick={() => setIsCreateEditCategoryOpen(true)}>Add meal plan</BlueButton>
                        </div>
                    </header>
                    <FoodCategoriesCollection
                        foodCategories={mealPlans}
                        setFoodCategories={setMealPlans}
                        handleCategoryClick={handleCategoryClick}
                        handleCategoryDeleteClick={(category) => setDeleteMealPlan(category)}
                        handleCategoryEditClick={handleCategoryEditClick}
                        handleCategoryReorder={onReorderMealPlans}
                        setIsCreateEditCategoryOpen={setIsCreateEditCategoryOpen}
                        handleClose={handleClose}
                        activeId={activeId}
                        setActiveId={setActiveId}
                    />
                </>
            );
        }
    };

    const onMealPlanDialogSubmit = (newValue) => {
        if (editingCategory) {
            editingCategory.title = newValue;
            onEditedMealPlan(editingCategory)
        } else {
            onCreateMealPlan(newValue)
        }
    }

    return (
        <>
            <CategoriesListContent />
            {isCreateEditCategoryOpen && (
                <AddFoodCategoryDialog
                    open={isCreateEditCategoryOpen}
                    onClose={() => setIsCreateEditCategoryOpen(false)}
                    title="Dialog Title"
                    onValueSubmit={onMealPlanDialogSubmit}
                    editingCategory={editingCategory}
                />
            )}
            <Dialog
                open={state == StateEnum.FILTEREDRECIPES}
                onClose={() => setState(StateEnum.MAIN)}
                maxWidth="md" fullScreen
            >
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setState(StateEnum.MAIN)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <span
                        style={{
                            fontSize: "22px",
                            fontWeight: "bold",
                            paddingLeft: '10px'
                        }}
                    >
                        {title}
                    </span>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Recipes
                        title={openMealPlan ? `Recipes > ${openMealPlan.title}` : "Recipes"}
                        openRecipe={openRecipe}
                        setOpenRecipe={setOpenRecipe}
                        onAddRecipe={onAddRecipe}
                        openMealPlan={openMealPlan}
                        foodCategories={mealPlans}
                        setLoadingText={setLoadingText}
                        onEditedRecipe={onEditedRecipe}
                        onDeleteRecipe={onDeleteRecipe}
                    />
                </DialogContent>
            </Dialog>
            <DeleteConfirmDialog
                open={deleteMealPlan != null}
                title={deleteMealPlan ? "Are you sure you want to delete " + deleteMealPlan.title + " meal plan?" : null}
                subtitle="Deleting a meal plan doesn't delete associated recipes"
                onConfirm={() => onDeleteMealPlan(deleteMealPlan)}
                onCancel={() => setDeleteMealPlan(null)}
            />
        </>
    );
};

const useStyles = makeStyles({
    mainTitle: {
        fontSize: 29,
        fontFamily: 'Inter',
        fontWeight: 600,
        color: '#000000',
    },
    categoriesTitle: {
        color: '#000000',
        fontSize: 20,
        fontFamily: 'Inter',
        fontWeight: 400,
    },
    categoryList: {
        display: 'flex',
        gap: 20,
        flexWrap: 'wrap',
    },
    header: {
        backgroundColor: 'white',
        padding: 5,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        marginBottom: 100,
    },
    headerButton: {
        marginLeft: '5px',
    },
    contentArea: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
    },
    categoriesExampleIcon: {
        maxWidth: 200,
    },
    categoriesExampleIcon2: {
        maxWidth: 300,
    },
    exampleContainer: {
        textAlign: 'center',
    },
    exampleTitle: {
        fontSize: 13,
        fontFamily: 'Inter',
        fontWeight: 500,
        marginBottom: 5,
        color: '#736F6F',
    },
    addCategoryWrapper: {
        width: '100%',
        height: '100%',
    },
    addCategoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '300px',
        margin: '0 auto',
    },
});

export default MealPlans;
