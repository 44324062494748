import React from "react";
import { Box, Avatar, Typography } from "@mui/material";

const VipUserHeader = ({ userVipCode, goal }) => {
    return (
        <Box>
            <Avatar
                src="/path-to-image.jpg"
                alt={userVipCode.name}
                sx={{ width: 56, height: 56 }}
            />

            <Box>
                <Typography variant="h6" fontWeight="bold">
                    {userVipCode.name}
                </Typography>

                <Box
                    sx={{
                        display: "inline-block",
                        bgcolor: "#464646",
                        color: "white",
                        textTransform: "none",
                        borderRadius: "8px",
                        fontSize: "12px",
                        padding: "5px 8px",
                    }}
                >
                    {goal}
                </Box>
            </Box>
        </Box>
    )
};

export default VipUserHeader;
