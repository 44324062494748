import React, { useState, useMemo, useContext } from 'react';
import BlueButton from 'src/Elements/BlueButton';
import EditableCategory from './EditableCategory/EditableCategory';
import PlanCalendar from './PlanCalendar/PlanCalendar';
import CreateEditCategoryDialog from './CreateEditCategoryDialog';
import TrainingPlans from './TrainingPlans/TrainingPlans';
import categoriesExampleIcon from 'src/Assets/new/categoriesExample.png';
import createCategoryExampleIcon2 from 'src/Assets/new/createCategoryExample2.png';
import { makeStyles } from '@material-ui/core/styles';
import { CategoriesContext } from './CategoriesContext';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { DndContext, closestCenter, useSensor, useSensors, KeyboardSensor, PointerSensor, DragOverlay } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, arrayMove, useSortable, horizontalListSortingStrategy } from '@dnd-kit/sortable';

const StateEnum = {
    MAIN: 0,
    EMPTY: 1,
    TRAINING_PLANS: 2,
    PLAN_CALENDAR: 3,
};

const Categories = ({ setLoadingText }) => {

    const [state, setState] = useState(StateEnum.MAIN);
    const classes = useStyles();
    const [isCreateEditCategoryOpen, setIsCreateEditCategoryOpen] = useState(false);

    const [newCategory, setNewCategory] = useState(null);

    const [editingCategory, setEditingCategory] = useState(null);
    const [openCategory, setOpenCategory] = useState(null);
    const [openPlan, setOpenPlan] = useState(null);
    const { 
        categories, 
        setCategories, 
        addCategoryByName, 
        updateCategoryIndexes,
        updateCategoryName, 
        updateCategoryPlan, 
        deleteCategory } = useContext(CategoriesContext);

    const [activeId, setActiveId] = useState(null);

    const title = openCategory
        ? `${openCategory.name} > ${openPlan?.name || ""}`
        : openPlan?.name || "";


    const handleCategoryClick = (category) => {
        setState(StateEnum.TRAINING_PLANS);
        setOpenCategory(category);
    };

    const onPlanClick = (plan) => {
        setState(StateEnum.PLAN_CALENDAR);
        setOpenPlan(plan);
    };

    const handleCategoryDeleteClick = async (categoryToDelete) => {
        setLoadingText("Deleting category...")
        try {
            await deleteCategory(categoryToDelete)
            setLoadingText(null)
        } catch (error) {
            setLoadingText(null)
            window.alert(error);
        }
    };


    const handleCategoryEditClick = (category) => {
        setEditingCategory(category);
        setIsCreateEditCategoryOpen(true);
    };

    const createEditCategorySubmit = async (categoryName) => {
        try {
            if (editingCategory) {
                setLoadingText("Updating category...")
                await updateCategoryName(editingCategory.id, categoryName)
                setEditingCategory(null);
                setLoadingText(null)
            } else {
                setLoadingText("Adding category...")
                const response = await addCategoryByName(categoryName);
                setNewCategory(response);
                setState(StateEnum.TRAINING_PLANS);
                setLoadingText(null)
            }
        } catch (error) {
            setLoadingText(null)
            alert(error);
        }
    }

    const onCategoryAdded = (newCategory) => {
        setCategories((prevCategories) => {
            return prevCategories.map((category) =>
                category.id === newCategory.id ? newCategory : category
            );
        });
        setNewCategory(null);
        setState(StateEnum.MAIN);
    }

    const onCategoryUpdated = (newCategory) => {
        setCategories((prevCategories) => {
            return prevCategories.map((category) =>
                category.id === newCategory.id ? newCategory : category
            );
        });

        if (openCategory.id === newCategory.id) {
            setOpenCategory(newCategory);
        }
    }

    const onPlanUpdated = (updatedPlan) => {
        const updatedCategory = updateCategoryPlan(updatedPlan);
        if (openCategory && updatedCategory && openCategory.id === updatedCategory.id) {
            setOpenCategory(updatedCategory);
        }
    }

    const onCalendarClose = () => {
        setState(StateEnum.TRAINING_PLANS);
    }

    const onTrainingPlansClose = () => {
        setOpenCategory(null)
        setState(StateEnum.MAIN);
    }

    const SortableCategoriesListContent = ({ activeId, setActiveId }) => {

        const sensors = useSensors(
            useSensor(PointerSensor),
            useSensor(KeyboardSensor, {
                coordinateGetter: sortableKeyboardCoordinates,
            })
        );

        const itemIds = useMemo(() => categories.map((category) => category.id.toString()), [categories]);

        const handleDragEnd = (event) => {
            const { active, over } = event;

            if (active.id !== over.id) {
                setCategories((categories) => {
                    const oldIndex = categories.findIndex((category) => category.id == active.id);
                    const newIndex = categories.findIndex((category) => category.id == over.id);

                    // Swap index values
                    const updatedCategories = [...categories];
                    const reorderedCategories = arrayMove(updatedCategories, oldIndex, newIndex);
                    // Move the items in the array
                    const finalCategories = reorderedCategories.map((category, index) => ({
                        ...category,
                        index: index, // Assign new index based on array position
                    }));

                    // Call handler to update backend
                    updateCategoryIndexes(finalCategories)

                    return reorderedCategories;
                });
            }

            setActiveId(null);
        };

        return (
            <DndContext sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={(event) => setActiveId(event.active.id)}
                onDragEnd={handleDragEnd}
            >
                <SortableContext items={itemIds} strategy={horizontalListSortingStrategy}>
                    <div className={classes.categoryList}>
                        {categories.map((category) => (
                            <EditableCategory
                                key={category.id}
                                category={category}
                                onCategoryClick={handleCategoryClick}
                                onDeleteClick={handleCategoryDeleteClick}
                                onEditClick={handleCategoryEditClick}
                            />
                        ))}
                    </div>
                </SortableContext>
                <DragOverlay>
                    {activeId ? (
                        <div className={classes.categoryList}>
                            <EditableCategory
                                key={"active"}
                                category={categories.find((category) => category.id.toString() === activeId)}
                                onCategoryClick={handleCategoryClick}
                                onDeleteClick={handleCategoryDeleteClick}
                                onEditClick={handleCategoryEditClick}
                            />
                        </div>
                    ) : null}
                </DragOverlay>
            </DndContext>
        )
    }

    const CategoriesListContent = ({ activeId, setActiveId} ) => {
        if (categories.length === 0) {
            return (
                <div className={classes.addCategoryWrapper}>
                    <div className={classes.addCategoryContainer}>
                        <img src={createCategoryExampleIcon2} alt="Categories Example" className={classes.categoriesExampleIcon2} />
                        <h3 className={classes.exampleTitle}>Lets add your first category. Categories contain workout plans and each plan contains a week of workouts</h3>
                        <BlueButton onClick={() => setIsCreateEditCategoryOpen(true)}>Add category</BlueButton>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <h1 className={classes.titleText}>Training categories</h1>
                    <header className={classes.header}>
                        <div className={classes.headerButton}>
                            <BlueButton onClick={() => setIsCreateEditCategoryOpen(true)}>Add category</BlueButton>
                        </div>
                    </header>
                    <h2 className={classes.categoriesTitle}>Categories</h2>
                    <div className={classes.contentArea}>
                        <SortableCategoriesListContent
                            activeId={activeId}
                            setActiveId={setActiveId}
                        />
                        <div className={classes.exampleContainer}>
                            <h3 className={classes.exampleTitle}>Categories Example</h3>
                            <img src={categoriesExampleIcon} alt="Categories Example" className={classes.categoriesExampleIcon} />
                        </div>
                    </div>
                </div>
            )
        }
    };

    return (
        <React.Fragment>
            {state === StateEnum.MAIN ?
                (<>
                    <CategoriesListContent
                        activeId={activeId}
                        setActiveId={setActiveId}
                    />
                    <CreateEditCategoryDialog
                        open={isCreateEditCategoryOpen}
                        onClose={() => setIsCreateEditCategoryOpen(false)}
                        title="Dialog Title"
                        onValueSubmit={createEditCategorySubmit}
                        editingCategory={editingCategory}
                    /></>
                ) : <TrainingPlans
                    newCategory={newCategory}
                    onCategoryAdded={onCategoryAdded}
                    openCategory={openCategory}
                    onPlanClick={onPlanClick}
                    onCategoryUpdated={onCategoryUpdated}
                    onClose={onTrainingPlansClose}
                    setLoadingText={setLoadingText}
                />}
            <Dialog open={state === StateEnum.PLAN_CALENDAR} onClose={onCalendarClose} maxWidth="md" fullWidth>
                <DialogContent>
                    <DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={onCalendarClose}
                        >
                            <CloseIcon />
                        </IconButton>
                        <span
                            style={{
                                fontSize: "22px",
                                fontWeight: "bold",
                                paddingLeft: '10px'
                            }}
                        >
                            {title}
                        </span>
                    </DialogTitle>
                    <PlanCalendar
                        plan={openPlan}
                        onPlanUpdated={onPlanUpdated}
                        onClose={onCalendarClose}
                        setLoadingText={setLoadingText}
                    />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

const useStyles = makeStyles({
    mainTitle: {
        fontSize: 29,
        fontFamily: 'Inter',
        fontWeight: 600,
        color: '#000000'
    },
    categoriesTitle: {
        color: '#000000',
        fontSize: 20,
        fontFamily: 'Inter',
        fontWeight: 400,
    },
    categoryList: {
        display: 'flex',
        gap: 20,
        flexWrap: 'wrap',
    },
    header: {
        backgroundColor: 'white',
        padding: 5,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        marginBottom: 100,
    },
    headerButton: {
        marginLeft: 'auto',
    },
    contentArea: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
    },
    categoriesExampleIcon: {
        maxWidth: 200,
    },
    categoriesExampleIcon2: {
        maxWidth: 300,
    },
    exampleContainer: {
        textAlign: 'center',
    },
    exampleTitle: {
        fontSize: 13,
        fontFamily: 'Inter',
        fontWeight: 500,
        marginBottom: 5,
        color: '#736F6F',
    },
    addCategoryWrapper: {
        width: '100%',
        height: '100%'
    },
    addCategoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '300px',
        margin: '0 auto',
    }
});

export default Categories;
