import React, { useState, useEffect, useContext } from 'react';
import BlueButton from 'src/Elements/BlueButton';
import EditableCode from './EditableCode';
import { makeStyles } from '@material-ui/core/styles';
import { CategoriesContext } from '../Categories/CategoriesContext';
import CreateVIPCodeDialog from './CreateVIPCodeDialog';
import UserVipCodeTabs from './UserVipCode/UserVipCodeTabs';
import BackButton from 'src/Elements/BackButton';

const UserVipCodeEnum = {
    CODES_LIST: 0,
    SELECTED_CODE: 1
};

const VipTraining = ({ setLoadingText }) => {

    const classes = useStyles();
    const [state, setState] = useState(UserVipCodeEnum.CODES_LIST);
    const [selectedUserVipCode, setSelectedUserVipCode] = useState(null);
    const {
        userVipCodes,
        fetchUserVipCodes,
        addVIPCode,
        deleteUserVipCode
    } = useContext(CategoriesContext);
    const [isCrateVIPCodeDialogOpen, setIsCrateVIPCodeDialogOpen] = useState(false);

    useEffect(() => {
        if (userVipCodes == null) {
            handleLoadCodes()
        }
    }, []);

    useEffect(() => {
        if (selectedUserVipCode && userVipCodes) {
            const matchingUserVipCode = userVipCodes.find(userVipCode => userVipCode.vipcode.code === selectedUserVipCode.vipcode.code);

            if (matchingUserVipCode) {
                setSelectedUserVipCode(matchingUserVipCode);
            }
        }
    }, [userVipCodes]);

    const handleLoadCodes = async () => {
        try {
            setLoadingText("Fetching codes");
            await fetchUserVipCodes();
            setLoadingText(null)
        } catch (error) {
            window.alert(error);
            setLoadingText(null)
        }
    };

    const onAddNewCode = async (name) => {
        try {
            setLoadingText("Adding Vip Code");
            await addVIPCode(name);
            setLoadingText(null)
        } catch (error) {
            window.alert(error);
            setLoadingText(null)
        }
    }


    const handleUserVipCodeDelete = async (userVipCode) => {
        try {
            setLoadingText("Deleting VIP Code");
            await deleteUserVipCode(userVipCode)
            setLoadingText(null)
        } catch (error) {
            window.alert(error);
            setLoadingText(null)
        }
    }

    const handleUserVipCodeClick = (userVipCode) => {
        setSelectedUserVipCode(userVipCode)
        setState(UserVipCodeEnum.SELECTED_CODE);
    }

    const handleUserVipCodeEdit = (userVipCode) => {
        setSelectedUserVipCode(userVipCode)
        setState(UserVipCodeEnum.SELECTED_CODE);
    }

    const VipCodesListContent = () => {

        if (userVipCodes == null) {
            return null;
        }

        if (userVipCodes.length === 0) {
            return (
                <div className={classes.addCategoryWrapper}>
                    <div className={classes.addCategoryContainer}>
                        <h3 className={classes.exampleTitle}>Let's add your first VIP user. Each VIP user has their own training plans.</h3>
                        <BlueButton onClick={() => setIsCrateVIPCodeDialogOpen(true)}>Add code</BlueButton>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <h1 className={classes.titleText}>VIP Codes</h1>
                    <header className={classes.header}>
                        <div className={classes.headerButton}>
                            <BlueButton onClick={() => setIsCrateVIPCodeDialogOpen(true)}>Add code</BlueButton>
                        </div>
                    </header>
                    <div className={classes.tableHeader}>
                        <div className={classes.userHeader}>User</div>
                        <div className={classes.codeNameHeader}>Code name</div>
                        <div className={classes.codeHeader}>Code</div>
                    </div>

                    <div className={classes.contentArea}>
                        <div className={classes.categoryList}>
                            {userVipCodes.map((userVipCode) => (
                                <EditableCode
                                    key={userVipCode.vipcode.code.code}
                                    userVipCode={userVipCode}
                                    onCodeClick={handleUserVipCodeClick}
                                    onDeleteClick={handleUserVipCodeDelete}
                                    onEditClick={handleUserVipCodeEdit}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            );
        }
    };


    return (
        <React.Fragment>
            {state === UserVipCodeEnum.CODES_LIST &&
                <>
                    <VipCodesListContent />
                    <CreateVIPCodeDialog
                        open={isCrateVIPCodeDialogOpen}
                        onClose={() => setIsCrateVIPCodeDialogOpen(false)}
                        title="Dialog Title"
                        onValueSubmit={onAddNewCode}
                    />
                </>}
            {state === UserVipCodeEnum.SELECTED_CODE && selectedUserVipCode &&
                <>
                <BackButton onClick={() => setState(UserVipCodeEnum.CODES_LIST)}/>
                    <UserVipCodeTabs
                        userVipCode={selectedUserVipCode}
                        setLoadingText={setLoadingText}
                    />
                </>
            }
        </React.Fragment>
    );
};

const useStyles = makeStyles({
    mainTitle: {
        fontSize: 29,
        fontFamily: 'Inter',
        fontWeight: 600,
        color: '#000000'
    },
    categoriesTitle: {
        color: '#000000',
        fontSize: 20,
        fontFamily: 'Inter',
        fontWeight: 400,
    },
    categoryList: {
        display: 'flex',
        gap: 20,
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        width: '100%',
        boxSizing: 'border-box',
    },
    header: {
        backgroundColor: 'white',
        padding: 5,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        marginBottom: 100,
    },
    tableHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        backgroundColor: '#e0e0e0',
        borderRadius: 10,
        fontWeight: 'bold',
        marginBottom: '10px',
    },
    userHeader: {
        flex: 2,
        textAlign: 'left',
        boxSizing: 'border-box',
    },
    codeNameHeader: {
        flex: 1,
        textAlign: 'left',
        boxSizing: 'border-box',
    },
    codeHeader: {
        flex: 1,
        textAlign: 'left',
        boxSizing: 'border-box',
    },
    headerButton: {
        marginLeft: 'auto',
    },
    contentArea: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
    },
    categoriesExampleIcon: {
        maxWidth: 200,
    },
    categoriesExampleIcon2: {
        maxWidth: 300,
    },
    addCategoryWrapper: {
        width: '100%',
        height: '100%'
    },
    addCategoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '300px',
        margin: '0 auto',
    }
});

export default VipTraining;
