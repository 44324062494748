import React, { createContext, useContext, useState, useEffect } from 'react';
import CategoriesReducer from 'src/Utilities/CategoriesReducer';
import { NetworkProvider } from 'src/NetworkProvider';
import { AuthContext } from 'src/AuthProvider';
import VipCodeReducer from '../VipTraining/UserVipCode/VipCodeReducer';
import { video_thumbnail_url_from_file_name } from 'src/Utilities/Utilities';
import UserVipMealPlans from '../VipTraining/UserVipMealPlans/UserVipMealPlans';

// Create a context
export const CategoriesContext = createContext();

// Create a provider component
export const CategoriesProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [userVipCodes, setUserVipCodes] = useState(null);
  const { loginCredentials, setLoginCredentials } = useContext(AuthContext);

  const [categoryExercises, setCategoryExercises] = useState([]);
  const [vipExercises, setVIPExercises] = useState([]);
  const [exercises, setExercises] = useState([]);

  const [categoryPlans, setCategoryPlans] = useState([]);
  const [vipPlans, setVIPPlans] = useState([]);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    if (userVipCodes == null) {
      setVIPExercises([])
      return;
    }

    let allExercises = [];
    let allPlans = [];

    for (const userVipCode of userVipCodes) {
      const vipCode = userVipCode.vipcode;
      const vipCategories = JSON.parse(vipCode.categories);

      if (vipCategories && vipCategories.length > 0) {
        const [vipExercises, vipPlans] = CategoriesReducer.getAllExercises(vipCategories);
        allExercises = [...allExercises, ...vipExercises];
        allPlans = [...allPlans, ...vipPlans];
      }
    }

    setVIPExercises(allExercises);
    setVIPPlans(allPlans)
  }, [userVipCodes]);

  useEffect(() => {
    const [exercises, plans] = CategoriesReducer.getAllExercises(categories)
    setCategoryExercises(exercises)
    setCategoryPlans(plans)
  }, [categories]);

  useEffect(() => {
    const combinedExercises = [...categoryExercises, ...vipExercises];
    setExercises(combinedExercises);
  }, [vipExercises, categoryExercises]);

  useEffect(() => {
    const combinedPlans = [...categoryPlans, ...vipPlans];
    setPlans(combinedPlans);
  }, [vipPlans, categoryPlans]);


  const fetchCategories = async () => {
    const categories = await NetworkProvider.get_categories(loginCredentials, setLoginCredentials)
    setCategories(CategoriesReducer.sortedCategories(categories))
  }

  const fetchUserVipCodes = async () => {
    const userCodes = await NetworkProvider.get_vip_codes(loginCredentials, setLoginCredentials)
    const sortedUserCodes = VipCodeReducer.sortedUserCodes(userCodes)
    setUserVipCodes(sortedUserCodes)
  };

  const addVIPCode = async (name) => {
    const newCode = await NetworkProvider.insert_vip_code(loginCredentials, setLoginCredentials, name)
    setUserVipCodes((prevCodes) => {
      const updatedCodes = [newCode, ...prevCodes];
      const sortedCodes = VipCodeReducer.sortedUserCodes(updatedCodes);
      return sortedCodes;
    });
  }

  const deleteUserVipCode = async (userVipCodeToDelete) => {
    const code = userVipCodeToDelete.vipcode.code;
    await NetworkProvider.delete_vip_code(loginCredentials, setLoginCredentials, code)
    setUserVipCodes((prevUserVipCodes) => prevUserVipCodes.filter((userVipCode) => userVipCode.vipcode.code !== userVipCodeToDelete.vipcode.code));
  }

  const updateUserVIPCodeCategories = async (userVipCode, category) => {
    const stringCategories = JSON.stringify([category]);
    const vipcode = userVipCode.vipcode;
    await NetworkProvider.update_vipcode_categories(loginCredentials, setLoginCredentials, vipcode, stringCategories);

    setUserVipCodes((prevUserVipCodes) => {
      const existingCodeIndex = prevUserVipCodes.findIndex((userVipCode) => userVipCode.vipcode.id === vipcode.id);

      if (existingCodeIndex !== -1) {
        const updatedUserVipCode = {
          ...prevUserVipCodes[existingCodeIndex],
          vipcode: {
            ...prevUserVipCodes[existingCodeIndex].vipcode,
            categories: stringCategories
          }
        };

        const updatedUserVipCodes = prevUserVipCodes.slice();
        updatedUserVipCodes[existingCodeIndex] = updatedUserVipCode;

        return updatedUserVipCodes;
      }
    });
  }

  const updateUserVIPCodeMealPlans = async (userVipCode, mealplans) => {
    const stringMealPlans = JSON.stringify(mealplans);
    const vipcode = userVipCode.vipcode;
    await NetworkProvider.update_vipcode_meal_plans(loginCredentials, setLoginCredentials, vipcode, stringMealPlans);

    setUserVipCodes((prevUserVipCodes) => {
      const existingCodeIndex = prevUserVipCodes.findIndex((userVipCode) => userVipCode.vipcode.id === vipcode.id);

      if (existingCodeIndex !== -1) {
        const updatedUserVipCode = {
          ...prevUserVipCodes[existingCodeIndex],
          vipcode: {
            ...prevUserVipCodes[existingCodeIndex].vipcode,
            mealplans: stringMealPlans
          }
        };

        const updatedUserVipCodes = prevUserVipCodes.slice();
        updatedUserVipCodes[existingCodeIndex] = updatedUserVipCode;

        return updatedUserVipCodes;
      }
    });
  }

  // MARK: Categories
  const addCategoryByName = async (categoryName) => {
    const response = await NetworkProvider.add_category(loginCredentials, setLoginCredentials, categoryName)
    setCategories([...categories, response])
    return response
  }

  const updateCategoryName = async (categoryId, categoryName) => {
    await NetworkProvider.update_category(loginCredentials, setLoginCredentials, categoryId, categoryName)
    setCategories((prevCategories) =>
      prevCategories.map((category) =>
        category.id === categoryId
          ? { ...category, name: categoryName }
          : category
      )
    );
  }
  const updateCategoryIndexes = async (newCategories) => {
    await NetworkProvider.update_category_indexes(loginCredentials, setLoginCredentials, newCategories)
  }

  const updateCategoryPlan = (updatedPlan) => {
    let updatedCategory = null;

    // Process the updated categories and identify the updated category before setting the state
    const newCategories = categories.map((category) => {
      const updatedPlans = category.plans.map((plan) =>
        plan.id === updatedPlan.id ? updatedPlan : plan
      );

      if (category.plans.some((plan) => plan.id === updatedPlan.id)) {
        updatedCategory = {
          ...category,
          plans: updatedPlans,
        };
        return updatedCategory; // This is the updated category
      }

      return {
        ...category,
        plans: updatedPlans,
      };
    });

    // Set the updated categories in state
    setCategories(newCategories);

    // Return the updated category
    return updatedCategory;
  };



  const deleteCategory = async (categoryToDelete) => {
    await Promise.all(categoryToDelete.plans.map(async (plan) => {
      try {
        await CategoriesReducer.deletePlan(loginCredentials, setLoginCredentials, plan);
        await NetworkProvider.delete_plan(loginCredentials, setLoginCredentials, plan.id);
        return
      } catch (error) {
        console.error(`Error deleting plan ${plan.id}:`, error);
        throw error;
      }
    }));
    await NetworkProvider.delete_category(loginCredentials, setLoginCredentials, categoryToDelete.id)
    setCategories((prevCategories) =>
      prevCategories.filter((category) =>
        category.id !== categoryToDelete.id
      )
    );
  }

  const deleteWeek = async (week) => {

    const videosToDelete = week.days.flatMap(day =>
      day.exercises.filter(ex => ex.video)
    );

    const videoCounts = exercises.reduce((counts, ex) => {
      if (ex.video) {
        counts[ex.video] = (counts[ex.video] || 0) + 1;
      }
      return counts;
    }, {});

    const filteredVideosToDelete = videosToDelete.filter(video => videoCounts[video] === 1);

    await Promise.all(filteredVideosToDelete.map(async (video) => {
      try {
        await NetworkProvider.delete_video(loginCredentials, setLoginCredentials, video);
        const thumbnailUrl = video_thumbnail_url_from_file_name(video, loginCredentials.authorId);
        if (thumbnailUrl) {
          await NetworkProvider.delete_image(loginCredentials, setLoginCredentials, thumbnailUrl);
        }
      } catch (error) {
        console.error(`Error deleting video ${video}:`, error);
        throw error;
      }
    }));
  };

  const deleteExercise = async (exercise) => {
    const video = exercise.video;

    // Check if the video appears more than once in the categories or vipcodes exercises
    const videoCount = exercises.filter((ex) => ex.video === video).length;

    if (video != null && videoCount == 1) {
      await NetworkProvider.delete_video(loginCredentials, setLoginCredentials, video);
      const thumbnailUrl = video_thumbnail_url_from_file_name(video, loginCredentials.authorId);

      if (thumbnailUrl) {
        await NetworkProvider.delete_image(loginCredentials, setLoginCredentials, thumbnailUrl)
      }
    }
  }

  const getPlanDetailsByPlanId = (planId) => {
    for (const plan of plans) {
      if (plan.id == planId) {
        return {
          name: plan.name,
          image: plan.image
        };
      }
    }
    return null;
  };

  return (
    <CategoriesContext.Provider value={{
      categories,
      setCategories,
      updateCategoryIndexes,
      exercises,
      userVipCodes,
      fetchCategories,
      addCategoryByName,
      updateCategoryName,
      updateCategoryPlan,
      deleteCategory,
      fetchUserVipCodes,
      addVIPCode,
      updateUserVIPCodeCategories,
      updateUserVIPCodeMealPlans,
      deleteUserVipCode,
      deleteExercise,
      deleteWeek,
      getPlanDetailsByPlanId
    }}>
      {children}
    </CategoriesContext.Provider>
  );
};