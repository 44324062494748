import React, { useState, useContext, useEffect } from 'react';
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CategoriesContext } from '../../CategoriesContext';
import BlueButton from 'src/Elements/BlueButton';

const ExercisePicker = ({ onExerciseSelected }) => {
    const classes = useStyles();
    const { exercises } = useContext(CategoriesContext);

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedExercise, setSelectedExercise] = useState(null);


    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleExerciseClick = (exercise) => {
        setSelectedExercise(exercise);
    };

    const onSelectClick = () => {
        onExerciseSelected(selectedExercise)
    }

    const filteredExercises = exercises.filter((exercise) =>
        exercise.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        exercise.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={classes.container}>
            <TextField
                label="Search existing exercises"
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.searchInput}
            />

            {/* Table of exercises */}
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredExercises.map((exercise, index) => (
                            <TableRow
                                key={index}
                                onClick={() => handleExerciseClick(exercise)}
                                className={selectedExercise === exercise ? classes.selectedRow : classes.row}
                            >
                                <TableCell>{exercise.name}</TableCell>
                                <TableCell>{exercise.description}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <div className={classes.bottomButtonContainer}>
                <BlueButton onClick={onSelectClick} className={classes.selectButton} disabled={selectedExercise == null}>
                    Select
                </BlueButton>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxHeight: '80vh',
        minHeight: '60vh'
    },
    searchInput: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    tableContainer: {
        flexGrow: 1,
        overflowY: 'auto',
        width: '100%',
    },
    row: {
        cursor: 'pointer',
    },
    selectedRow: {
        backgroundColor: '#f0f8ff',
        border: '2px solid #007bff',
    },
    bottomButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        marginTop: 'auto',
        gap: 20,
        paddingTop: theme.spacing(2),
    },
}));


export default ExercisePicker;
