import React, { useMemo, useState } from 'react';
import { DndContext, closestCenter, useSensor, useSensors, KeyboardSensor, PointerSensor, DragOverlay } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, arrayMove, useSortable, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import BlueButton from 'src/Elements/BlueButton';
import categoriesExampleIcon from 'src/Assets/new/categoriesExample.png';
import { makeStyles } from '@material-ui/core/styles';
import EditableFoodCategory from './EditableFoodCategory';

const Category = ({ category, handleCategoryClick, handleCategoryDeleteClick, handleCategoryEditClick, handleCategoryReorder }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: category.id.toString(),
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0 : 1,
        pointerEvents: isDragging ? 'none' : 'auto',
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes}>
            <EditableFoodCategory
                category={category}
                onCategoryClick={handleCategoryClick}
                onDeleteClick={handleCategoryDeleteClick}
                onEditClick={handleCategoryEditClick}
                listeners={listeners}
            />
        </div>
    );
};

const FoodCategoriesCollection = ({ foodCategories, setFoodCategories, recipes, handleCategoryClick, handleCategoryDeleteClick, handleCategoryEditClick, setIsCreateEditCategoryOpen, handleCategoryReorder, activeId, setActiveId }) => {
    const classes = useStyles();

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            setFoodCategories((categories) => {
                const oldIndex = categories.findIndex((category) => category.id == active.id);
                const newIndex = categories.findIndex((category) => category.id == over.id);

                // Swap index values
                const updatedCategories = [...categories];
                const reorderedCategories = arrayMove(updatedCategories, oldIndex, newIndex);
                // Move the items in the array
                const finalCategories = reorderedCategories.map((category, index) => ({
                    ...category,
                    index: index, // Assign new index based on array position
                }));

                // Call handler to update backend or UI
                handleCategoryReorder(finalCategories);

                return reorderedCategories;
            });
        }

        setActiveId(null);
    };


    const itemIds = useMemo(() => foodCategories.map((category) => category.id.toString()), [foodCategories]);

    if (foodCategories.length === 0) {
        return (
            <div className={classes.addCategoryWrapper}>
                <div className={classes.addCategoryContainer}>
                    <img src={categoriesExampleIcon} alt="Categories Example" className={classes.categoriesExampleIcon2} />
                    <h3 className={classes.exampleTitle}>
                        Lets add your first food category. Food categories contain a list of recipes
                    </h3>
                    <BlueButton onClick={() => setIsCreateEditCategoryOpen(true)}>Add category</BlueButton>
                </div>
            </div>
        );
    } else {
        return (
            <div className={classes.contentArea}>
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={(event) => setActiveId(event.active.id)} onDragEnd={handleDragEnd}>
                    <SortableContext items={itemIds} strategy={horizontalListSortingStrategy}>
                        <div className={classes.categoryList}>
                            {foodCategories.map((category) => (
                                <Category
                                    key={category.id}
                                    category={category}
                                    handleCategoryClick={handleCategoryClick}
                                    handleCategoryDeleteClick={handleCategoryDeleteClick}
                                    handleCategoryEditClick={handleCategoryEditClick}
                                />
                            ))}
                        </div>
                    </SortableContext>
                    <DragOverlay>
                        {activeId ? (
                            <div className={classes.categoryList}>
                                <Category
                                    category={foodCategories.find((category) => category.id.toString() === activeId)}
                                    handleCategoryClick={handleCategoryClick}
                                    handleCategoryDeleteClick={handleCategoryDeleteClick}
                                    handleCategoryEditClick={handleCategoryEditClick}
                                />
                            </div>
                        ) : null}
                    </DragOverlay>
                </DndContext>
            </div>
        );
    }
};

const useStyles = makeStyles({
    categoriesTitle: {
        color: '#000000',
        fontSize: 20,
        fontFamily: 'Inter',
        fontWeight: 400,
    },
    categoryList: {
        display: 'flex',
        gap: 20,
        flexWrap: 'wrap',
    },
    contentArea: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
    },
    categoriesExampleIcon: {
        maxWidth: 200,
    },
    categoriesExampleIcon2: {
        maxWidth: 300,
    },
    exampleContainer: {
        textAlign: 'center',
    },
    exampleTitle: {
        fontSize: 13,
        fontFamily: 'Inter',
        fontWeight: 500,
        marginBottom: 5,
        color: '#736F6F',
    },
    addCategoryWrapper: {
        width: '100%',
        height: '100%',
    },
    addCategoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '300px',
        margin: '0 auto',
    },
});

export default FoodCategoriesCollection;
